import * as theMovieDb from '../lib/themoviedb';

export function getPosterUrl(result) {
    var items = [result];
    if (result.seasons) {
        items.push(...result.seasons.reverse());
    }
    for (var item of items) {
        if (item.poster_path) {
            return theMovieDb.common.getImage({ size: 'w154', file: item.poster_path }) as string;
        }
    }
}