import { appModule } from '../main';
appModule.directive('on', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        scope: {
            on: '@',
            do: '&'
        },
        link: function ($scope: any, $element, $attributes) {
            $element[0].addEventListener($scope.on, function (event) {
                $scope.$applyAsync(() => {
                    $scope.$eval($scope.do)
                    $scope.do({ $customEvent: event, value: event.detail.value });
                });
            });
        }
    }
}]);