
import { appModule, firebaseApp } from '../../main';
import { auth, firestore } from 'firebase/app';
let dayjs = require('dayjs');

class AppController {
    public static $inject = ['$scope'];
    constructor(
        public $scope: ng.IScope
    ) {
        this.db = firestore();
        this.db.settings({});

        //init firebase auth
        firebaseApp.auth().onAuthStateChanged((user) => {
            this.currentUser = user;
            if (this.currentUser) {
                //load the list of shows for the user
                this.db.collection('showsByUser').doc(this.currentUser.uid).get().then((doc) => {
                    var data = doc.data();
                    this.shows = data && data.shows ? data.shows : [];
                    //transform the old format into the new
                    for (let show of this.shows) {
                        if ((show as any).lastWatchedDate) {
                            show.watchDates = [
                                (show as any).lastWatchedDate
                            ];
                            delete (show as any).lastWatchedDate;
                        }
                    }
                    this.sort();
                    $scope.$apply();
                });
            }
        });
    }

    public selectedShow: Show;
    public isAddShowVisible = false;
    public shows = [] as Show[];
    public db: firebase.firestore.Firestore;
    public currentUser: firebase.User;
    public itemToEdit: Show;
    public isArchiveMode = false;

    public get isLoggedIn() {
        return !!this.currentUser;
    }

    public logIn() {
        var provider = new auth.GoogleAuthProvider();
        firebaseApp.auth().signInWithPopup(provider).then((response) => {
            this.currentUser = response.user;
        }).catch(console.error);
    };

    public logOut() {
        var provider = new auth.GoogleAuthProvider();
        firebaseApp.auth().signOut().then(() => {
            this.currentUser = undefined;
            this.shows = [];
            this.itemToEdit = undefined;
            this.isAddShowVisible = false;
            this.$scope.$apply();
        }).catch(console.error);
    };

    public addShow(show: Show) {
        if (this.shows.find(x => x.posterUrl === show.posterUrl) !== undefined) {
            alert('This show is already in your list');
        } else {
            this.shows.push(show);
        }
        this.isAddShowVisible = false;
        this.save();
    }

    public deleteShow(show) {
        if (confirm('Are you sure you want to delete this show?')) {
            this.shows.splice(this.shows.indexOf(show), 1);
            this.save();
            this.selectedShow = undefined;
        }
    };

    public archiveShow(show) {
        if (confirm('Are you sure you want to archive this show?')) {
            show.isArchived = true;
            this.save();
            this.selectedShow = undefined;
        }
    }

    public restoreShow(show) {
        show.isArchived = false;
        this.save();
        this.selectedShow = undefined;
    }

    public updateSelectedShow(show: Show) {
        Object.assign(this.selectedShow, show);
        this.selectedShow = undefined;
        this.save();
        this.sort();
    }

    public save() {
        let confirmed = true;
        if (!this.shows || this.shows.length === 0) {
            confirmed = confirm('You are saving an empty list of shows. Are you sure you want to proceed?');
        }
        if (confirmed) {
            var shows = this.shows.slice();
            //delete the $$hashKey property
            shows.forEach(show => delete (show as any).$$hashKey);

            this.db.collection('showsByUser').doc(this.currentUser.uid).set({
                shows: shows
            });
        }
    };

    public sort() {
        //sort every show's watch dates
        for (let show of this.shows) {
            show.watchDates.sort(function (a, b) {
                var val = a.toMillis() - b.toMillis();
                if (val > 0) {
                    return -1;
                } else if (val < 0) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        //sort shows by their latest watch date watchDates[0]
        this.shows.sort(function (a, b) {
            var val = a.watchDates[0].toMillis() - b.watchDates[0].toMillis();
            if (val > 0) {
                return 1;
            } else if (val < 0) {
                return -1;
            } else {
                return 0;
            }
        });
    };

    public getDateText(timestamp) {
        var d: any = new Date(timestamp.toMillis());
        var text = dayjs(d).format('MMM D, YYYY');
        return text;
    };

    public log(cmd) {
        console.log(cmd);
    };

    public saveEdit() {
        this.itemToEdit = undefined;
        this.sort();
        this.save();
    }
}

export interface Show {
    tmdbId: number;
    name: string;
    posterUrl: string;
    watchDates: firebase.firestore.Timestamp[];
}

appModule.component('app', {
    controller: AppController,
    template: require('./app.html')
});
