import { appModule } from '../../main';
import { Show } from '../app/app';
import * as  firebase from 'firebase';
let dayjs = require('dayjs');
import * as theMovieDb from '../../../lib/themoviedb';
import { getPosterUrl } from '../../util';

export class ShowInfoModal {
    constructor() {

    }

    /**
     * Indicates whether we are currently in edit mode
     */
    public get isEditMode() {
        return !!this.showBeingEdited;
    }

    public getTmdbSearchByNameUrl() {
        return `https://www.themoviedb.org/search?language=en-US&query=${encodeURI(this.showBeingEdited.name)}`;
    }

    public getPosterUrl() {
        return new Promise<string>((resolve, reject) => {
            theMovieDb.tv.getById({ "id": this.showBeingEdited.tmdbId }, (responseJson) => {
                let result = JSON.parse(responseJson);
                var posterUrl = getPosterUrl(result);
                resolve(posterUrl);
            }, (error) => {
                console.error(error);
                reject(error);
            });
        });
    }

    /**
     * Internal copy of the show so we can "undo" if need be
     */
    public show: Show;
    public showBeingEdited: Show;
    public isArchiveMode: boolean;

    /**
     * Enables edit mode by making a copy of the show so we can do edits without changing the actual object
     */
    public enableEditMode() {
        this.showBeingEdited = Object.assign({}, this.show);
        //make a copy of the watch dates
        this.showBeingEdited.watchDates = this.showBeingEdited.watchDates.slice();
    }

    public getHistoryDateText(timestamp: firebase.firestore.Timestamp) {
        var d: any = new Date(timestamp.toMillis());
        return dayjs(d).format('ddd MMM D, YYYY');
    }

    public deleteWatchDate(index: number) {
        if (this.showBeingEdited.watchDates.length === 1) {
            alert('Cannot delete the last history record');
            return;
        }
        this.showBeingEdited.watchDates.splice(index, 1);
    }

    public async save() {
        //if we have a tmdbID, re-fetch its poster URL
        if (this.showBeingEdited.tmdbId) {
            let posterUrl = await this.getPosterUrl();
            if (posterUrl) {
                this.showBeingEdited.posterUrl = posterUrl;
                this.show.posterUrl = posterUrl;
            }
        }

        this.onSave({ show: this.showBeingEdited });
        this.showBeingEdited = undefined;
    }

    /**
     * Called when a user clicks the "cancel" button from the edit mode control bar
     */
    public cancelEditMode() {
        this.showBeingEdited = undefined;
    }

    public watchNow() {
        this.enableEditMode();
        this.showBeingEdited.watchDates.unshift(firebase.firestore.Timestamp.now());
        this.save();
    }


    public getIsoString(firebaseDate) {
        return (new Date(firebaseDate.toMillis())).toISOString();
    }

    public setDateValue(index, isoString: string) {
        this.showBeingEdited.watchDates[index] = firebase.firestore.Timestamp.fromDate(new Date(isoString));
    }

    public onSave: any;
    public onClose: any;
}

appModule.component('showInfoModal', {
    controller: ShowInfoModal,
    bindings: {
        isArchiveMode: '=',
        show: '<',
        onSave: '&',
        onClose: '&',
        onDelete: '&',
        onRestore: '&',
        onArchive: '&'
    },
    template: require('./show-info-modal.html')
});