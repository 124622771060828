import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/app';
import 'firebase/database';
import 'themoviedb-javascript-library';
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.css';
import * as angular from 'angular';
import * as theMovieDb from '../lib/themoviedb';

// put this at the top of your code
if ((module as any).hot) {
    (module as any).hot.dispose(() => {
        window.location.reload();
        throw 'whatever'
    })
}

Object.assign(theMovieDb.common, {
    api_key: "90dbc17887e30eae3095d213fa803190",
    base_uri: "https://api.themoviedb.org/3/",
    images_uri: "https://image.tmdb.org/t/p/"
});

// Initialize Firebase
var config = {
    apiKey: "AIzaSyCctseZ2UzZ1TeIeR41efwHolphQXQBQU8",
    authDomain: "media-tracker-81c93.firebaseapp.com",
    databaseURL: "https://media-tracker-81c93.firebaseio.com",
    projectId: "media-tracker-81c93",
    storageBucket: "",
    messagingSenderId: "206251775306"
};
export var firebaseApp = firebase.initializeApp(config);

export var appModule = angular.module('app', []);

appModule.run(['$q', ($q) => {
    window.Promise = $q;
}]);
let originalComponent = appModule.component;
(appModule as any).component = (name, options) => {
    options = Object.assign({
        controllerAs: 'vm'
    }, options);
    return originalComponent(name, options);
};


//load all compomponents
require('./components/**/*.ts');

//load all directives
require('./directives/**/*.ts');

//include all stylesheets
import './style.less';
import './**/*.less';