import { appModule } from '../../main';
import * as theMovieDb from '../../../lib/themoviedb';
import * as firebase from 'firebase';
import { Show } from '../app/app';
import { getPosterUrl } from '../../util';

export class AddShowModal {
    public static $inject = ['$scope'];
    constructor(
        public $scope: ng.IScope
    ) {

    }

    public searchText: string;
    public searchResults = [];

    public getSearchResults() {
        theMovieDb.search.getMulti({
            query: this.searchText,
        }, (response) => {
            this.searchResults = JSON.parse(response).results;
            for (var i = 0; i < this.searchResults.length; i++) {
                var result = this.searchResults[i];
                result.posterUrl = getPosterUrl(result);
                result.name = result.name || result.title;
                result.date = result.first_air_date || result.release_date;
                //try to parse out only the year
                result.date = result.date.replace(/(\d\d\d\d)-\d\d-\d\d/, '$1');
            }
            this.$scope.$apply();
        }, (error) => {
        });
    }


    public addShow(searchResult) {
        var show = {
            name: searchResult.name,
            posterUrl: searchResult.posterUrl,
            tmdbId: searchResult.id,
            watchDates: [firebase.firestore.Timestamp.now()]
        } as Show;
        this.onAdd({ show: show })
        this.searchText = '';
        this.searchResults = [];
    }

    public onAdd: any;
    public onClose: any;

}


appModule.component('addShowModal', {
    controller: AddShowModal,
    bindings: {
        onAdd: '&',
        onClose: '&'
    },
    template: require('./add-show-modal.html')
})